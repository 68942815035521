import React from "react";
import { graphql } from "gatsby";
import BlogList from "../templates/blog-list";

const BlogIndex = ({ data, location }) => {
  // Create the same context structure that gatsby-node.js provides to other pages
  const pageContext = {
    currentPage: 1,
    numPages: Math.ceil(data.allMarkdownRemark.totalCount / 10),
  };

  return <BlogList data={data} pageContext={pageContext} location={location} />;
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        fileAbsolutePath: { regex: "/(posts)/" },
        # Show English versions for translated posts or the only version if post has just one language
        fields: {
          shouldDisplay: { eq: true }
        }
      }
      limit: 10
      skip: 0
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            language
            isTranslated
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            tag
          }
        }
      }
    }
  }
`; 