import React from "react";
import { Link, graphql } from "gatsby";
import BlogBody from "../pages/blogPosts";
import HeaderSEO from "../components/seo";
import "./pagination.css";

const Pagination = ({ currentPage, numPages, prevPage, nextPage, isFirst, isLast }) => (
  <nav className="pagination">
    {!isFirst && (
      <Link 
        to={prevPage} 
        rel="prev" 
        className="pagination-link pagination-prev"
      >
        <span className="pagination-arrow">←</span>
        <span className="pagination-text">Previous</span>
      </Link>
    )}
    
    <span className="pagination-info">
      {currentPage}/{numPages}
    </span>

    {!isLast && (
      <Link 
        to={nextPage} 
        rel="next" 
        className="pagination-link pagination-next"
      >
        <span className="pagination-text">Next</span>
        <span className="pagination-arrow">→</span>
      </Link>
    )}
  </nav>
);

const BlogList = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/" : `/page/${currentPage - 1}`;
  const nextPage = `/page/${currentPage + 1}`;

  // Collect all tags from posts for keywords
  const allTags = posts.reduce((tags, { node }) => {
    return [...tags, ...(node.frontmatter.tag || [])];
  }, []);
  const uniqueTags = [...new Set(allTags)];

  const paginationProps = {
    currentPage,
    numPages,
    prevPage,
    nextPage,
    isFirst,
    isLast
  };

  return (
    <div>
      <HeaderSEO 
        title={isFirst ? "Blog" : `Blog - Page ${currentPage} of ${numPages}`}
        description={`Blog posts page ${currentPage} of ${numPages}. ${data.site.siteMetadata.description}`}
        keywords={uniqueTags}
        image={"/cover.jpg"}
        meta={[
          {
            name: "robots",
            content: "index, follow"
          },
          ...(prevPage ? [{
            name: "prev",
            content: prevPage
          }] : []),
          ...(nextPage ? [{
            name: "next",
            content: nextPage
          }] : [])
        ]}
      />
      {!isFirst && <Pagination {...paginationProps} />}
      <BlogBody posts={posts} currentPath={location.pathname} />
      <Pagination {...paginationProps} />
    </div>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        fileAbsolutePath: { regex: "/(posts)/" },
        fields: {
          shouldDisplay: { eq: true }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            language
            isTranslated
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            tag
          }
        }
      }
    }
  }
`; 